<template>
  <div>
    <h3>{{ $t('reportgoodtrash.title') }}</h3>
    <br />
    <v-divider></v-divider> <br />
    <v-card outlined rounded>
      <v-card-title>
        {{ $t('all.find') }}
        <v-menu
          v-model="mdte1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dte1"
              :label="$t('all.from')"
              prepend-icon="fa-calendar"
              readonly
              v-on="on"
              @blur="date1 = parseDate(dte1)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date1" @input="mdte1 = false" locale="ru-ru"></v-date-picker>
        </v-menu>

        <v-menu
          v-model="mdte2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dte2"
              :label="$t('all.to')"
              prepend-icon="fa-calendar"
              readonly
              v-on="on"
              @blur="date2 = parseDate(dte2)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date2" @input="mdte2 = false" locale="ru-ru"></v-date-picker>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="dataLoad()">
          {{ $t('all.showreport') }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table v-bind:headers="headers" :items="rows" hide-actions class="elevation-1">
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.good_name }}</td>
              <td>{{ props.item.barcode }}</td>
              <td>
                <span class="pull-right">{{ props.item.quantity }}</span>
              </td>
              <td>
                <span class="pull-right">{{ props.item.cost }}</span>
              </td>
              <td>
                <span class="pull-right">{{ props.item.total }}</span>
              </td>
            </tr>
          </template>
        </v-data-table>
        Кол-во товаров: {{ totalcount }}; Сумма {{ totalsmm }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: 'Товар',
          align: 'left',
          value: 'good_name',
        },
        {
          text: 'Штрихкод',
          align: 'left',
          value: 'barcode',
        },
        {
          text: 'Кол-во',
          value: 'quantity',
        },
        {
          text: 'Стоимость',
          value: 'cost',
        },
        {
          text: 'Итого',
          align: 'right',
          value: 'total',
        },
      ],
      rows: [],
      tableLoad: false,
      tableData: ['load'],
      dteformat: 'DD.MM.YYYY',
      dte1: '',
      dte2: '',
      date1: null,
      date2: null,
      flgtrue: true,
      totalcount: 0,
      totalsmm: 0,
    }
  },
  computed: {},
  components: {},
  mounted: function() {
    var dt = new Date()
    var month = dt.getMonth() + 1
    var day = dt.getDate()
    var year = dt.getFullYear()
    month = month < 10 ? '0' + month : month
    day = day < 10 ? '0' + day : day
    this.dte2 = day + '.' + month + '.' + year
    this.dte1 = '01.' + month + '.' + year
    //        this.dataLoad()
  },
  methods: {
    dteUpd1(v) {
      this.dte1 = v
    },
    dteUpd2(v) {
      this.dte2 = v
    },
    formatDate(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate(date) {
      if (!date) {
        return null
      }
      const [dte] = date.split(' ')
      const [day, month, year] = dte.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    dataLoad: function() {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'report/goodtrash/', {
          dte_start: this.dte1,
          dte_finish: this.dte2,
        })
        .then(
          response => {
            if (response.body.err > 0) {
              // t.tableData = ["error"];
            } else {
              t.rows = response.body.data
              t.totalcount = response.body.total.quantity
              t.totalsmm = response.body.total.total
            }
          },
          err => {
            console.log(err)
          },
        )
    },
  },
}
</script>

<style></style>
